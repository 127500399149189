.slick-dots {
  bottom: -8px;
}

.slick-dots li button:before {
  content: "";
  background: #9e9e9e;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.slick-dots li.slick-active button:before {
  content: "";
  background: #d6d6d6;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  width: 6px;
  height: 6px;
  cursor: pointer;
}

.slick-dots li button {
  width: 0;
  height: 0;
  padding: 0;
}

.slick-next {
  display: none !important;
}
