@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

html,
body {
  background: black;
  font-family: Montserrat, sans-serif;
  padding: 0;
  margin: 0;
  width: 360px;
  height: 600px;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Satoshi", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: white;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2f3142;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212121;
}

@keyframes connect {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.scene {
  width: 310px;
  height: 210px;
  perspective: 620px;
}
* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.cube {
  width: 310px;
  height: 210px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-200px);
  transition: transform 1s;
}

.cube.show-web2 {
  transform: translateZ(-160px) rotateY(0deg);
}
.cube.show-all {
  transform: translateZ(-260px) rotateY(-45deg);
}
.cube.show-web3 {
  transform: translateZ(-160px) rotateY(-90deg);
}

.cube__face {
  position: absolute;
  width: 310px;
  top: 0px;
  left: 1px;
}

.cube__face--web2 {
  transform: rotateY(0deg) translateZ(155px);
}
.cube__face--web3 {
  transform: rotateY(90deg) translateZ(155px);
}
label {
  margin-right: 10px;
}
